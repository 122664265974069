/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';
import { getContrastingColor, hexToRGB } from '../../lib/utils';

import { usePage } from '../../contexts/PageContext';
import { useUrlGenerator } from '@folklore/routes';
import { withTheme } from '../../contexts/SiteContext';
import BurgerButton from '../buttons/Burger';
import Quatre95 from '../icons/logos/Quatre95';
import Quatre95Tag from '../icons/logos/Quatre95Tag';
import Link from '../partials/Link';
import SiteSponsor from '../partials/SiteSponsor';

// import NeonText from './NeonText';
import styles from '../../../styles/headers/header-quatre95.module.scss';

const propTypes = {
    theme: PropTypes.oneOf(['normal', 'inverted', 'dark']),
    sticky: PropTypes.bool,
    textColor: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    shadowColor: PropTypes.string.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    headerRef: AppPropTypes.ref,
};

const defaultProps = {
    theme: 'normal',
    sticky: false,
    className: null,
    headerRef: null,
};

const Header = ({
    theme,
    textColor,
    iconColor,
    backgroundColor,
    shadowColor,
    className,
    sticky,
    headerRef,
    toggleMenu,
}) => {
    const generateUrl = useUrlGenerator();
    const correctedTextColor =
        textColor === backgroundColor ? getContrastingColor(backgroundColor) : textColor;
    const onClickLogo = useTrackOnClickLink('click_header_logo');
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.sticky]: sticky,
                    [className]: className !== null,
                },
            ])}
            ref={headerRef}
        >
            <div
                className={styles.bar}
                style={{
                    color: correctedTextColor,
                    backgroundColor,
                    boxShadow: sticky ? `0 1px 4px ${hexToRGB(shadowColor, 0.2)}` : null,
                }}
            >
                <div className={styles.inner}>
                    <div className={styles.side}>
                        <Link
                            href={generateUrl('home')}
                            className={styles.tag}
                            onClick={onClickLogo}
                        >
                            <Quatre95Tag className={styles.icon} inverted={theme === 'inverted'} />
                        </Link>
                    </div>
                    <div className={styles.center}>
                        <div className={styles.side} />
                        <Link
                            href={generateUrl('home')}
                            className={styles.title}
                            onClick={onClickLogo}
                        >
                            <Quatre95 className={styles.logo} />
                        </Link>
                        <div className={styles.side}>
                            <SiteSponsor
                                className={styles.sponsor}
                                logoClassName={styles.logo}
                                color={iconColor}
                                monochrome={theme !== 'normal'}
                            />
                        </div>
                    </div>
                    <div className={styles.side}>
                        <BurgerButton
                            className={styles.button}
                            color={correctedTextColor}
                            onClick={toggleMenu}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const WithColorsContainer = withTheme(Header, ({ header = {} }, { theme = 'normal' }) => {
    const { textColor, iconColor, backgroundColor, shadowColor } = header[theme] || {};
    return {
        textColor,
        iconColor,
        backgroundColor,
        shadowColor,
    };
});

// Get the theme from the current page
function WithThemeContainer(props) {
    const page = usePage();
    const theme = useMemo(() => {
        const { pageType = null, type = null, handle = null } = page;
        if (pageType === 'article' && type === 'video') {
            return 'dark';
        }
        if (pageType === 'page' && handle === 'home') {
            return 'normal';
        }
        return 'inverted';
    }, [page]);
    return <WithColorsContainer {...props} theme={theme} />;
}

export default WithThemeContainer;
