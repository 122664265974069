/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';

import * as AppPropTypes from '../../lib/PropTypes';

import Link from '../partials/Link';
import NeonText from '../partials/NeonText';

import styles from '../../../styles/menus/rolodex.module.scss';

const propTypes = {
    items: AppPropTypes.menuItems,
    colors: AppPropTypes.colors,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    onClickItem: PropTypes.func,
};

const defaultProps = {
    items: [],
    colors: ['#5e79ff', '#ffe52c', '#f04e3e', '#ffffff', '#f7b7c1'],
    className: null,
    itemClassName: null,
    onClickItem: null,
};

function Rolodex({ items, colors, className, itemClassName, onClickItem }) {
    const [overIndex, setOverIndex] = useState(null);
    const startIndex = useMemo(() => Math.floor(Math.random() * colors.length), [colors]);
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.firstIsCurrent]: overIndex === 0,
                    [className]: className !== null,
                },
            ])}
        >
            {items.map((it, index) => {
                const { id = index, label, url } = it;
                return (
                    <div
                        key={`item-${id}`}
                        className={classNames([
                            styles.item,
                            {
                                [itemClassName]: itemClassName !== null,
                            },
                        ])}
                    >
                        <Link
                            href={url}
                            onClick={(e) =>
                                onClickItem !== null ? onClickItem(e, it, index) : null
                            }
                            onMouseEnter={() => {
                                setOverIndex(index);
                            }}
                            className={classNames([
                                styles.link,
                                {
                                    [styles.current]: index === overIndex,
                                },
                            ])}
                        >
                            <span className={styles.inner}>
                                <NeonText
                                    className={styles.text}
                                    color={colors[(startIndex + index) % colors.length]}
                                    glow={overIndex === index}
                                >
                                    {label.toLowerCase()}
                                </NeonText>
                            </span>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
}

Rolodex.propTypes = propTypes;
Rolodex.defaultProps = defaultProps;

export default Rolodex;
