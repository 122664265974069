/* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'wouter';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import * as AppPropTypes from '../../lib/PropTypes';
import { withTheme } from '../../contexts/SiteContext';
import useSwipe from '../../hooks/useSwipe';
import { useUrlGenerator } from '@folklore/routes';

import SiteSponsor from '../partials/SiteSponsor';
import BurgerButton from '../buttons/Burger';
import Search from '../forms/Search';
// import SearchOpenButton from '../buttons/Search';
import Rolodex from './Rolodex';
import Menu from './Menu';

import styles from '../../../styles/menus/main-menu-quatre95.module.scss';

const propTypes = {
    sectionsMenu: AppPropTypes.menu,
    pagesMenus: PropTypes.arrayOf(AppPropTypes.menu),
    opened: PropTypes.bool.isRequired,
    burgerButtonColor: AppPropTypes.color.isRequired,
    linksMenuColor: AppPropTypes.color.isRequired,
    closeMenu: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    sectionsMenu: [],
    pagesMenus: [],
    className: null,
};

const MainMenuQuatre95 = ({
    sectionsMenu,
    pagesMenus,
    opened,
    burgerButtonColor,
    linksMenuColor,
    closeMenu,
    toggleMenu,
    className,
}) => {
    const refContainer = useRef(null);
    const generateUrl = useUrlGenerator();
    const [,setLocation] = useLocation();
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (opened) {
            disableBodyScroll(refContainer.current);
        } else {
            enableBodyScroll(refContainer.current);
        }
        return () => {
            enableBodyScroll(refContainer.current);
        };
    }, [opened]);

    const onSearchSubmit = useCallback(
        searchingQuery => {
            setLocation(`${generateUrl('search')}?q=${encodeURIComponent(searchingQuery)}`);
            closeMenu();
        },
        [closeMenu, setLocation, generateUrl],
    );

    const onMenuClick = useCallback(() => toggleMenu(), [toggleMenu]);

    const onSwipeRight = useCallback(() => {
        if (opened) {
            closeMenu();
        }
    }, [opened, closeMenu]);
    const bindSwipe = useSwipe({
        onSwipeRight
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.opened]: opened,
                    [className]: className !== null,
                },
            ])}
            {...bindSwipe()}
        >
            <div className={styles.top}>
                <SiteSponsor className={styles.sponsor} logoClassName={styles.logo} />
                <BurgerButton
                    className={styles.menu}
                    color={burgerButtonColor}
                    menuOpened={opened}
                    onClick={onMenuClick}
                />
            </div>

            <div className={styles.search}>
                <Search
                    className={styles.form}
                    inputRef={searchInputRef}
                    onSubmit={onSearchSubmit}
                />
            </div>

            <div className={styles.menus}>
                <div className={styles.mainMenu}>
                    <Rolodex items={sectionsMenu.items} />
                </div>
                <div
                    className={styles.linksMenu}
                    style={{
                        color: linksMenuColor,
                    }}
                >
                    {pagesMenus.map(({ items }, index) => (
                        <div key={`list-${index}`} className={styles.list}>
                            <Menu
                                items={items}
                                className={styles.menu}
                                subMenuClassName={styles.subMenu}
                                itemClassName={styles.item}
                                hasSubMenuClassName={styles.hasSubMenu}
                                openedClassName={styles.opened}
                                linkClassName={styles.link}
                                labelClassName={styles.label}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

MainMenuQuatre95.propTypes = propTypes;
MainMenuQuatre95.defaultProps = defaultProps;

const WithThemeContainer = withTheme(
    MainMenuQuatre95,
    ({ header: { inverted: { iconColor } = {}, linksMenuColor } = {} }) => ({
        burgerButtonColor: iconColor,
        linksMenuColor,
    }),
);

export default WithThemeContainer;
