import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTrackOnClickLink } from '../../hooks/useTracking';
import * as AppPropTypes from '../../lib/PropTypes';

import { useSmallLayout } from '../../contexts/LayoutContext';
import { useSite } from '../../contexts/SiteContext';
import ArticleFeaturedCard from '../cards/ArticleFeaturedCard';
import CollectionRow from '../lists/CollectionRow';
import SectionRow from '../lists/SectionRow';
import PageMeta from '../meta/PageMeta';
import Ad from '../partials/Ad';

import styles from '../../../styles/pages/home-quatre95.module.scss';

const propTypes = {
    page: AppPropTypes.page.isRequired,
    count: PropTypes.number,
    isDisabled: PropTypes.number,
    onMetadataChange: PropTypes.func,
};

const defaultProps = {
    count: 3,
    isDisabled: false,
    onMetadataChange: null,
};

function HomeQuatre95Page({ page, count, isDisabled, onMetadataChange }) {
    const site = useSite();
    const layoutIsSmall = useSmallLayout();
    const { sections: allSections = [] } = site;
    const { featuredArticle = null, featuredCollection = null, sections = [] } = page;
    const sectionQuery = useMemo(
        () =>
            featuredArticle !== null
                ? {
                      exclude: featuredArticle.id,
                      cache: true,
                  }
                : { cache: true },
        [featuredArticle],
    );
    const homeSections = allSections.filter(({ id }) => sections.indexOf(id) !== -1);
    const articlesSection = allSections.find(({ id }) => id === 'articles');
    const videoSection = allSections.find(({ id }) => id === 'videos');
    const sectionsWithoutVideo = homeSections.filter(({ id }) => id !== 'videos');
    const onClickSectionItem = useTrackOnClickLink('click_home_section_item');

    return (
        <main className={styles.container}>
            {!isDisabled ? (
                <PageMeta page={page} withoutTitleSuffix onChange={onMetadataChange} />
            ) : null}
            {featuredArticle !== null ? <ArticleFeaturedCard item={featuredArticle} /> : null}
            <Ad slot="top" alwaysRender />
            <section>
                <SectionRow
                    section={articlesSection}
                    query={sectionQuery}
                    title={
                        <FormattedMessage
                            defaultMessage="Derniers contenus"
                            description="Block title"
                        />
                    }
                    isCarousel={layoutIsSmall}
                    onClickItem={onClickSectionItem}
                    withLazyLoading
                />
            </section>
            {featuredCollection ? (
                <section>
                    <CollectionRow
                        collection={featuredCollection}
                        headerClassName={styles.collectionHeader}
                        isCarousel={layoutIsSmall}
                    />
                </section>
            ) : null}
            <section>
                <SectionRow
                    section={videoSection}
                    query={sectionQuery}
                    isCarousel={layoutIsSmall}
                    moreLabel={
                        <FormattedMessage
                            defaultMessage="Voir plus de vidéos"
                            description="Button label"
                        />
                    }
                    onClickItem={onClickSectionItem}
                />
            </section>
            {sectionsWithoutVideo.map((section, i) => (
                <section key={`section-${section.id}`}>
                    {i % 2 === 0 ? (
                        <div className={classNames([styles.ad, styles.withMargin])}>
                            <Ad slot="fullwidth" />
                        </div>
                    ) : null}
                    <SectionRow
                        section={section}
                        query={sectionQuery}
                        isCarousel={layoutIsSmall}
                        adsFrequency={i % 2 === 0 ? 4 : 3}
                        count={count}
                        headerClassName={styles.sectionHeader}
                        onClickItem={onClickSectionItem}
                        withLazyLoading
                    />
                </section>
            ))}
        </main>
    );
}

HomeQuatre95Page.propTypes = propTypes;
HomeQuatre95Page.defaultProps = defaultProps;

export default HomeQuatre95Page;
