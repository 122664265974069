import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../../styles/buttons/burger.module.scss';

const propTypes = {
    color: PropTypes.string,
    menuOpened: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {
    color: null,
    menuOpened: false,
    className: null,
};

const BurgerButton = ({ color, menuOpened, className, onClick }) => (
    <button
        className={classNames([
            styles.container,
            {
                [styles.isActive]: menuOpened,
                [className]: className !== null,
            },
        ])}
        style={{ color }}
        type="button"
        onClick={onClick}
    >
        <span className={styles.inner}>
            <span className={styles.bars}>
                <span
                    className={classNames([styles.bar, styles.first])}
                    style={{ backgroundColor: color }}
                />
                <span className={styles.bar} style={{ backgroundColor: color }} />
                <span
                    className={classNames([styles.bar, styles.otherBar])}
                    style={{ backgroundColor: color }}
                />
                <span
                    className={classNames([styles.bar, styles.last])}
                    style={{ backgroundColor: color }}
                />
            </span>
        </span>
    </button>
);

BurgerButton.propTypes = propTypes;
BurgerButton.defaultProps = defaultProps;

export default BurgerButton;
